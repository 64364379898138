import React, { Component } from "react";
import Card from "@material-ui/core/Card";
import ButtonBase from "@material-ui/core/ButtonBase";
import StoreInfo from "./StoreInfo.js";

export default class StoreInfoCard extends Component {
  render() {
    return (
      <div className="storeInfoCard">
        <Card>
          <ButtonBase className="cardButton" onClick={this.props.onClick}>
            <StoreInfo
              cssClass="cardInfoText"
              cusName={this.props.cusName}
              addr1={this.props.addr1}
              addr2={this.props.addr2}
              phoneNum={this.props.phoneNum}
              phoneNum2={this.props.phoneNum2}
              shopLink={this.props.shopLink}
              latlng={this.props.latlng}
              newPostion={this.props.newPostion}
              currentlocationData={this.props.currentlocationData}
              searchResultCheck={this.props.searchResultCheck}
            />
          </ButtonBase>
        </Card>
      </div>
    );
  }
}
