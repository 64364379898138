import * as React from "react";
import { isMobile } from "react-device-detect";
import SearchIcon from "@mui/icons-material/Search";
import TextField from "@mui/material/TextField";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";

export default function SearchBar(props) {
  const [value, setValue] = React.useState("");

  React.useEffect(() => {
    if (!props.searchResultCheck) {
      setValue("");
    }
  }, [props.searchResultCheck]);

  // const handleChange = (event) => {
  //   const {
  //     target: { value },
  //   } = event;
  //   setPersonName(
  //     // On autofill we get a stringified value.
  //     typeof value === "string" ? value.split(",") : value
  //   );
  // };
  // const handleArrayChanges = (event, value) => {
  //   if (value && value.length > 0) {
  //     const data = array
  //       .filter((data) => value.includes(data.District))
  //       .map((filteredName) => {
  //         return filteredName;
  //       });
  //     setPersonName(data);
  //   }
  //   console.log(value);
  // };

  return (
    <>
      <Autocomplete
        value={value}
        size="small"
        onChange={(event, newValue) => {
          if (typeof newValue === "string") {
            setValue({
              title: newValue,
            });

            props.getSearchResult([newValue]);
          } else if (newValue && newValue.inputValue) {
            // Create a new value from the user input
            setValue({
              title: newValue.inputValue,
            });

            props.getSearchResult([newValue.inputValue]);
          } else {
            setValue(newValue);

            props.getSearchResult([newValue]);
          }
        }}
        //   filterOptions={(options, params) => {
        //     const filtered = filter(options, params);

        //     const { inputValue } = params;
        //     // Suggest the creation of a new value
        //     const isExisting = options.some(
        //       (option) => inputValue === option.title + option.addr
        //     );
        //     if (inputValue !== "" && !isExisting) {
        //       filtered.push({
        //         inputValue,
        //         title: `Add "${inputValue}"`,
        //       });
        //     }

        //     return filtered;
        //   }}
        filterOptions={createFilterOptions({
          matchFrom: "any",
          ignoreCase: true,
          stringify: (option) => option.addr + option.tel + option.cusName + option.District+option.Tehsil,
        })}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        id="Search"
        options={props.stores}
        forcePopupIcon={true}
        popupIcon={<SearchIcon />}
        getOptionLabel={(option) => {
          // Value selected with enter, right from the input
          if (typeof option === "string") {
            return option;
          }
          // Add "xxx" option created dynamically
          if (option.inputValue) {
            return option.inputValue;
          }
          // Regular option
          return option.addr;
        }}
        renderOption={(props, option) => (
          // <li
          //   {...props}
          // >{`${option.addr}, ${option.Tehsil}, ${option.District}`}</li>
          <li {...props} key={option.key}>
            {option.addr}
          </li>
        )}
        sx={{ width: isMobile ? '100%' : 300 }}
        freeSolo
        renderInput={(params) => (
          <TextField
            {...params}
            inputProps={{
              ...params.inputProps,
              onKeyDown: (e) => {
                if (e.key === "Enter") {
                  e.stopPropagation();
                }
              },
            }}
            label="Search by Name, Mobile No."
          />
        )}
      />
    </>
  );
}
