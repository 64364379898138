import React, { Component } from "react";
import ReactDOM from "react-dom";
import ReactGA from "react-ga";
import { Map, InfoWindow, Marker, GoogleApiWrapper } from "google-maps-react";
import { isMobile } from "react-device-detect";
import Paper from "@material-ui/core/Paper";
import Drawer from "@material-ui/core/Drawer";
import { Snackbar } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
// import ButtonBase from "@material-ui/core/ButtonBase";
// import Fab from "@material-ui/core/Fab";
// import ExpandLess from "@material-ui/icons/ExpandLess";
import ListIcon from "@mui/icons-material/List";
import MapOutlinedIcon from "@mui/icons-material/MapOutlined";
// import ExpandMore from "@material-ui/icons/ExpandMore";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import StoreInfoCard from "./StoreInfoCard.js";
import StoreInfo from "./StoreInfo.js";
import storeData from "./storeData.json";
import fawStoreData from "./fawStoreData.json";
import ShowAllStores from "./showAllStores.js";
import SearchFilter from "./searchFilter";
import SearchFilterMobile from "./serachFilterMobile";
import ClearData from "./clearData";
// import isEqual from "lodash/isEqual";
// import Carousel from "react-material-ui-carousel";
// import Slider from "react-slick";
// import 'slick-carousel/slick/slick.css';
// import 'slick-carousel/slick/slick-theme.css';
import isEmpty from "lodash/isEmpty";
import SearchBar from "./searchBar";
import ShowDailog from "./showDialog";
import Typography from '@material-ui/core/Typography';
const apiKey = "AIzaSyB5xpes0VamYY1w7AQ6FnW8P5zg7V8mAP4";
// const apiKey = "AIzaSyAdjAeE5HjFe1DstwNlLKBQ1ed2N0eFBe0";
const geocodeApiKey = "AIzaSyBGWigHo_bVBeRXOuTFq2lmohIgGByAbUw";
const apiLinkHeader =
  "https://maps.googleapis.com/maps/api/geocode/json?address=";
// const normalPinUrl = "https://imgur.com/hu4m0FF.png";
// const highlightedPinUrl = "https://imgur.com/p4ApzNi.png";
// const normalPinUrl = "https://imgur.com/uS4Dlmd.png";
// const highlightedPinUrl = "https://imgur.com/N6ERMRb.png";
const normalPinUrl = "https://grower-console-qa-assets.s3.ap-southeast-1.amazonaws.com/Indonesia-images/normalPinUrl.png";
const highlightedPinUrl = "https://grower-console-qa-assets.s3.ap-southeast-1.amazonaws.com/Indonesia-images/highlightedPinUrl.png";
const directionIconUrl = "https://grower-console-qa-assets.s3.ap-southeast-1.amazonaws.com/Indonesia-images/directionIconUrl.png";

let stores = storeData
  .filter((obj) => obj.geoData.results[0] !== undefined)
  .filter((obj) => obj.postalCode !== "");

let fawStores = fawStoreData.filter(
  (obj) => obj.geoData.results[0] !== undefined
);

const distanceThreshold = 40; // km

const bangkokCoord = { lat: 30.1575, lng: 71.5249 };

export class MapContainer extends Component {
  constructor(props) {
    super(props);
    window.amplitude
      .getInstance()
      .logEvent("Store_Visit_PK", { Name: "Store_Visit" });
    const filterParams = new URLSearchParams(window.location.search);
    const filterFaw = filterParams.get("filter") === "faw";
    this.state = {
      // visibleStores: filterFaw
      //   ? fawStores
      //   : stores
      //       .filter((obj) => this.storeFilter(obj, bangkokCoord))
      //       .sort((x, y) => this.storeComparator(x, y, bangkokCoord)),
      visibleStores: [],
      coord: "",
      zoom: 12,
      showingInfoWindow: false,
      activeMarker: {},
      selectedPlace: {},
      drawerOpen: false,
      fawCheckbox: filterFaw,
      currSearchCoord: bangkokCoord,
      currentlocation: {},
      nearStore: bangkokCoord,
      isStoreEmpty: false,
      directionService: {},
      directions: null,
      latlng: {},
      searchResult: {},
      showAllStores: false,
      showDialog: false,
      showDefault: false,
      newVisileStores : []
    };

    this.normalPinObj = {
      url: normalPinUrl,
      anchor: new this.props.google.maps.Point(0, 32),
      origin: new this.props.google.maps.Point(0, 0),
      scaledSize: new this.props.google.maps.Size(20, 32),
    };

    this.highlightPinObj = {
      url: highlightedPinUrl,
      anchor: new this.props.google.maps.Point(0, 32),
      origin: new this.props.google.maps.Point(0, 0),
      scaledSize: new this.props.google.maps.Size(20, 32),
    };
    this.directionIcon = {
      url: directionIconUrl,
    };
    this.getUserLoc = this.getUserLoc.bind(this);
    this.onMarkerClick = this.onMarkerClick.bind(this);
    this.onMapClick = this.onMapClick.bind(this);
    this.cleanString = this.cleanString.bind(this);
    this.getAddr1 = this.getAddr1.bind(this);
    this.getAddr2 = this.getAddr2.bind(this);
    
    this.getShopLink = this.getShopLink.bind(this);
    this.storeInfoCardOnClick = this.storeInfoCardOnClick.bind(this);
    this.onKeyDownSearch = this.onKeyDownSearch.bind(this);
    this.distanceBtwn = this.distanceBtwn.bind(this);
    this.getPhoneNum = this.getPhoneNum.bind(this);
    this.storeFilter = this.storeFilter.bind(this);
    this.storeComparator = this.storeComparator.bind(this);
    this.searchStores = this.searchStores.bind(this);
    this.fetchHelper = this.fetchHelper.bind(this);
    this.highlightPin = this.highlightPin.bind(this);
    this.renderCheckbox = this.renderCheckbox.bind(this);
    this.fawCheckboxOnChange = this.fawCheckboxOnChange.bind(this);
    this.infoWindowOnOpen = this.infoWindowOnOpen.bind(this);
  }

  componentDidMount() {
    // this.getUserLoc();
    var getCoord = this.createStoreData(stores[0]);
    if (navigator && navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (pos) => {
          this.reverseGeocode();
          this.setState({ showDefault: false });
        },
        (error) => {
          if (error.code === error.PERMISSION_DENIED) {
            this.setState({
              showAllStores: true,
              coord: getCoord.location[0],
              zoom: 6,
              showDialog: true,
              showDefault: true,
            });
          }
        },
        { enableHighAccuracy: false, timeout: 10000, maximumAge: 0 }
      );
    }
    // this.getDirection();
  }
  componentDidUpdate(preProps, nextProps) {
    var getCoord = this.createStoreData(stores[0]);
    if (
      nextProps.visibleStores !== this.state.visibleStores &&
      isEmpty(this.state.visibleStores)
    ) {
      this.setState({
        showAllStores: true,
        coord: getCoord.location[0],
        zoom: 6,
      });
    }
  }
  getAllStores = (val) => {
    var getCoord = this.createStoreData(stores[0]);
    this.setState({
      coord: getCoord.location[0],
    });

    if (val) {
      this.setState({ searchResult: false });
    }
    this.setState({ showAllStores: val, zoom: 6 });
  };
  getSearchResult = (val) => {
    if (val.length > 0 && val[0] !== null) {
      var getCoord = this.createStoreData(val[0]);

      this.setState({
        coord: getCoord.location[0],
      });
    }
    if (val === false) {
      this.setState({ searchResult: val, showAllStores: true });
    } else {
      this.setState({ searchResult: val, showAllStores: false });
    }
  };
  getDistance = (obj, currCoord) => {
    let StoreDataLatLng = this.createStoreData(obj);
    let storeCoord = StoreDataLatLng.location[0];
    fetch(
      "https://maps.googleapis.com/maps/api/distancematrix/json?destinations=" +
        storeCoord.lat +
        "," +
        storeCoord.lng +
        "&origins=" +
        currCoord.lat +
        "," +
        currCoord.lng +
        "," +
        "&key=" +
        geocodeApiKey
    )
      .then((response) => response.json())
      .then((responseJson) => {
      });

    // let storeCoord = obj.geoData.results[0].geometry.location;

    this.distanceBtwn(
      currCoord.lat,
      currCoord.lng,
      storeCoord.lat,
      storeCoord.lng
    );
  };
  clearAllData = (val) => {
    if (val) {
      this.setState({
        searchResult: false,
        showAllStores: false,
      });
      this.reverseGeocode();
    }
  };
  getDirection = () => {
    const directionsService = new this.props.google.maps.DirectionsService();
    const origin = { lat: 6.5244, lng: 3.3792 };
    const destination = { lat: 6.4667, lng: 3.45 };
    directionsService.route(
      {
        origin: origin,
        destination: destination,
        travelMode: this.props.google.maps.TravelMode.DRIVING,
        waypoints: [
          {
            location: new this.props.google.maps.LatLng(6.4698, 3.5852),
          },
          {
            location: new this.props.google.maps.LatLng(6.6018, 3.3515),
          },
        ],
      },
      (result, status) => {
        if (status === this.props.google.maps.DirectionsStatus.OK) {
          this.setState({
            directions: result,
          });
        } else {
          console.error(`error fetching directions ${result}`);
        }
      }
    );
  };

  reverseGeocode = () => {
    // let lat = "30.045633";
    // let lng = "72.058049";
    // let currCoord = { lat: "30.045633", lng: "72.058049" };
    if (navigator && navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (pos) => {
          let currCoord = {
            lat: pos.coords.latitude,
            lng: pos.coords.longitude,
          };
          let lat = pos.coords.latitude;
          let lng = pos.coords.longitude;

          fetch(
            "https://maps.googleapis.com/maps/api/geocode/json?latlng=" +
              lat +
              "," +
              lng +
              "&key=" +
              geocodeApiKey +
              "&language=en"
          )
            .then((response) => response.json())
            .then((responseJson) => {
              let visibleStoresData = stores
                .filter((obj) => this.storeFilter(obj, currCoord, responseJson))
                .sort((x, y) => this.storeComparator(x, y, currCoord));
              // let distanceData = stores.filter((obj) =>
              //   this.getDistance(obj, currCoord, responseJson)
              // );
              // if (visibleStoresData.length === 0) {
              //   this.reverseGeocodeDefault();
              //   return;
              // }
              // if (visibleStoresData.length === 0) {
              //   this.setState({ showAllStores: true });
              //   // return;
              // }
              let storeLatLng = this.createStoreData(visibleStoresData);
              let findNearStore =
                visibleStoresData && visibleStoresData[0]
                  ? storeLatLng.location[0]
                  : "";

              this.setState({
                // coord:
                //   visibleStoresData.length === 0
                //     ? storeLatLng.location[0]
                //     : currCoord,
                coord: currCoord,
                currentlocation: currCoord,
                currSearchCoord: currCoord,
                zoom: 8,
                // visibleStores:
                //   visibleStoresData.length === 0 ? stores : visibleStoresData,
                visibleStores: visibleStoresData,
                isStoreEmpty: visibleStoresData.length === 0 ? true : false,
                nearStore: findNearStore,
              });
            });
        },
        (error) => {
          var getCoord = this.createStoreData(stores[0]);
          if (error.code === error.PERMISSION_DENIED) {
            this.setState({
              showAllStores: true,
              coord: getCoord.location[0],
              zoom: 6,
              showDialog: true,
              showDefault: true,
            });
          }
        },
        { enableHighAccuracy: false, timeout: 10000, maximumAge: 0 }
      );
    }
  };
  reverseGeocodeDefault = () => {
    let lat = "30.045633";
    let lng = "72.058049";
    let currCoord = { lat: "30.045633", lng: "72.058049" };

    fetch(
      "https://maps.googleapis.com/maps/api/geocode/json?latlng=" +
        lat +
        "," +
        lng +
        "&key=" +
        geocodeApiKey +
        "&language=en"
    )
      .then((response) => response.json())
      .then((responseJson) => {
        let visibleStoresData = stores
          .filter((obj) => this.storeFilter(obj, currCoord, responseJson))
          .sort((x, y) => this.storeComparator(x, y, currCoord));
        // let distanceData = stores.filter((obj) =>
        //   this.getDistance(obj, currCoord, responseJson)
        // );

        let storeLatLng = this.createStoreData(visibleStoresData);

        let findNearStore =
          visibleStoresData && visibleStoresData[0]
            ? storeLatLng.location[0]
            : "";

        this.setState({
          coord: currCoord,
          currentlocation: currCoord,
          currSearchCoord: currCoord,
          zoom: 11,
          visibleStores: stores,
          isStoreEmpty: visibleStoresData.length === 0 ? true : false,
          nearStore: findNearStore,
        });
      });
  };
  getUserLoc() {
    if (navigator && navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((pos) => {
        let currCoord = { lat: pos.coords.latitude, lng: pos.coords.longitude };

        this.setState({
          coord: currCoord,
          currSearchCoord: currCoord,
          zoom: 15,
          visibleStores: stores
            .filter((obj) => this.storeFilter(obj, currCoord))
            .sort((x, y) => this.storeComparator(x, y, currCoord)),
        });
      });
    }
  }
  createStoreData = (obj) => {
    return {
      location: [
        {
          lat: obj.latitude,
          lng: obj.longitude,
        },
      ],
    };
  };
  storeFilter(obj, currCoord, responseJson) {
    // let postalCodeCurrent;
    // if (responseJson) {
    //   postalCodeCurrent = responseJson.results[0].address_components.find(
    //     (item) => {
    //       return item.types.includes("postal_code");
    //     }
    //   );
    //   postalCodeCurrent =
    //     postalCodeCurrent && postalCodeCurrent.short_name
    //       ? postalCodeCurrent.short_name
    //       : "";
    // }

    // let postalCodeData = obj.geoData.results.map((address) => {
    //   let details = address.address_components.find((type) =>
    //     type.types.includes("postal_code")
    //   );
    //   return details && details.short_name ? details.short_name : false;
    // });

    let details;
    if (responseJson) {
      details = responseJson.results[0].address_components.find((item) => {
        return (
          item.types.includes("administrative_area_level_2") ||
          item.types.includes("administrative_area_level_1")
        );
      });
      details = details && details.short_name ? details.short_name : "";
    }
    let StoreDataLatLng = this.createStoreData(obj);
    // let storeCoord = obj.geoData.results[0].geometry.location;
    let storeCoord = StoreDataLatLng.location[0];

    let countryCode = obj.geoData.results.map((address) => {
      let details = address.address_components.find(
        (type) =>
          type.types.includes("administrative_area_level_2") ||
          type.types.includes("administrative_area_level_1")
      );
      return details && details.short_name ? details.short_name : false;
    });

    if (details === countryCode[0]) {
    }

    //  let postCodeDateString = postalCodeData.toString();
    return (
      this.distanceBtwn(
        currCoord.lat,
        currCoord.lng,
        storeCoord.lat,
        storeCoord.lng
      ) <= distanceThreshold && countryCode[0] === details
    );
  }

  compare = (wordOne, wordTwo) => {
    if (wordOne.substring(0, 1) === wordTwo.toString().substring(0, 1)) {
      return true;
    } else {
      return false;
    }
  };
  storeComparator(x, y, currCoord) {
    let xCoord = x.geoData.results[0].geometry.location;
    let yCoord = y.geoData.results[0].geometry.location;

    return (
      this.distanceBtwn(currCoord.lat, currCoord.lng, xCoord.lat, xCoord.lng) -
      this.distanceBtwn(currCoord.lat, currCoord.lng, yCoord.lat, yCoord.lng)
    );
  }

  onMarkerClick(props, marker) {
    this.setState({
      showingInfoWindow: true,
      activeMarker: marker,
      selectedPlace: props,
    });
    let eventProperties = {
      Name: "Marker",
      action: "Map Marker Store Click",
      label: props.cusName,
    };
    window.amplitude.getInstance().logEvent("Marker_Click_PK", eventProperties);
    ReactGA.event({
      category: "Stores",
      action: "Map Marker Store Click",
      label: props.cusName,
    });
  }

  onMapClick() {
    if (this.state.showingInfoWindow) {
      this.setState({
        showingInfoWindow: false,
        activeMarker: null,
      });
    }
  }

  cleanString(str) {
    if (str === undefined) {
      return "";
    } else {
      return str;
    }
  }

  getAddr1(obj) {
    return `${this.cleanString(obj.addr)}`;
    // ${this.cleanString(obj.amphur)}`;
  }

  getAddr2(obj) {
    return `${this.cleanString(obj.Zone)}`;
    //${this.cleanString(obj.provinceCode)}`;
  }
  getShopLink(obj) {
    return `${this.cleanString(obj.shopLink)}`;
    //${this.cleanString(obj.provinceCode)}`;
  }
  storeInfoCardOnClick(obj, key) {
    let storeInfo = this.createStoreData(obj);

    this.setState({
      newVisileStores: this.highlightPin(this.state.showAllStores ? stores:  this.state.visibleStores.length > 0 ? this.state.visibleStores : this.state.searchResult.length > 0 ? this.state.searchResult : stores , key),
      coord: storeInfo.location[0],
      zoom: 18,
      latlng: storeInfo.location[0],
    });
    let eventProperties = {
      Name: "Marker",
      action: "Info Panel Store Click",
      label: obj.cusName,
    };
    window.amplitude
      .getInstance()
      .logEvent("Info_Panel_Click_PK", eventProperties);

    ReactGA.event({
      category: "Stores",
      action: "Info Panel Store Click",
      label: obj.cusName,
    });
  }

  highlightPin(storesArr, key) {
    let outputArr = [...storesArr];

    outputArr.forEach((x) => delete x.pinHighlighted);

    outputArr[key].pinHighlighted = true;
    return outputArr;
  }

  onKeyDownSearch(event) {
    if (event.keyCode === 13) {
      this.searchStores(event);
      let eventProperties = {
        Name: "Search",
        action: "Searched Location",
        label: event.target.value.cusName,
      };
      window.amplitude.getInstance().logEvent("Search", eventProperties);
      ReactGA.event({
        category: "Search",
        action: "Searched Location",
        label: event.target.value,
      });

      if (isMobile) {
        event.target.blur();
        // this.setState({drawerOpen: false});
      }
    }
  }

  async searchStores(event) {
    let searchLoc = await this.fetchHelper(
      encodeURI(
        `${apiLinkHeader}${event.target.value}&components=country:pk&key=${geocodeApiKey}`
      )
    );

    if (searchLoc.status === "OK") {
      let searchCoord = searchLoc.results[0].geometry.location;
      let visibleStores = (this.state.fawCheckbox ? fawStores : stores)
        .filter((obj) => this.storeFilter(obj, searchCoord))
        .sort((x, y) => this.storeComparator(x, y, searchCoord));

      this.setState({
        coord: visibleStores[0].geoData.results[0].geometry.location,
        currSearchCoord: searchCoord,
        zoom: 15,
        visibleStores: visibleStores,
      });
    }
  }

  async fetchHelper(str) {
    let res = await fetch(str);
    return await res.json();
  }
  handleClose = () => {
    this.setState({
      isStoreEmpty: false,
    });
  };
  distanceBtwn(lat1, lng1, lat2, lng2) {
    const R = 6371; // km
    let dLat = ((lat2 - lat1) * Math.PI) / 180;
    let dLng = ((lng2 - lng1) * Math.PI) / 180;
    let a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos((lat1 * Math.PI) / 180) *
        Math.cos((lat2 * Math.PI) / 180) *
        Math.sin(dLng / 2) *
        Math.sin(dLng / 2);
    let c = 2 * Math.asin(Math.sqrt(a));
    let d = R * c;
    return d;
  }

  getPhoneNum(tel, mobile) {
    if (tel !== "" && tel !== undefined) {
      return (tel = tel.split(",")[0]);
    }
    if (mobile === undefined) return tel;

    mobile = mobile.split(",")[0];

    if (tel !== "") {
      return tel;
    } else {
      return mobile;
    }
  }

  fawCheckboxOnChange(event) {
    // change url
    window.history.replaceState(
      "",
      "",
      this.state.fawCheckbox ? "/" : "?filter=faw"
    );

    this.setState({
      fawCheckbox: event.target.checked,
      visibleStores: (this.state.fawCheckbox ? stores : fawStores)
        .filter((obj) => this.storeFilter(obj, this.state.currSearchCoord))
        .sort((x, y) => this.storeComparator(x, y, this.state.currSearchCoord)),
    });
  }

  renderCheckbox() {
    return (
      <div className={isMobile ? "checkboxMobile" : "checkbox"}>
        <FormControlLabel
          control={
            <Checkbox
              checked={this.state.fawCheckbox}
              onChange={this.fawCheckboxOnChange}
              style={{ display: "none" }}
            />
          }
          // label="Fall Armyworm Store Location"
        />
      </div>
    );
  }

  infoWindowOnOpen() {
    const storeInfo = (
      <StoreInfo
        cssClass={"infoWindowText"}
        cusName={this.state.selectedPlace.cusName}
        addr1={this.state.selectedPlace.address1}
        addr2={this.state.selectedPlace.address2}
        phoneNum={this.state.selectedPlace.phoneNum}
        phoneNum2={this.state.selectedPlace.phoneNum2}
        shopLink={this.state.selectedPlace.shopLink}
        latlng={this.state.selectedPlace.position}
        open={this.state.showDefault}
        // newPostion={this.state.selectedPlace.newPostion}
        currentlocationData={this.state.currentlocation}
      />
    );
    ReactDOM.render(
      React.Children.only(storeInfo),
      document.getElementById("insideInfoWindow")
    );
  }

  render() {

    // const settings = {
    //   dots: true,
    //   infinite: true,
    //   speed: 500,
    //   slidesToShow: 1,
    //   slidesToScroll: 1,
    // };

    return (
      <div>
        <Typography>
        <ShowDailog openVal={this.state.showDialog} />
        {!isMobile && (
          <>
            <div className="showTopBar">
              <div style={{ paddingRight: "5px" }}>
                <SearchBar
                  stores={stores}
                  visibleStores={this.state.visibleStores}
                  getSearchResult={this.getSearchResult}
                  searchResultCheck={this.state.searchResult}
                />
              </div>
              <div className="showAllStores">
                <SearchFilter
                  allStores={stores}
                  getSearchResult={this.getSearchResult}
                  searchResultCheck={this.state.searchResult}
                />
                <span>Filter</span>
              </div>
              <div className="showAllStores">
                <ShowAllStores
                  getAllStores={this.getAllStores}
                  searchResultCheck={this.state.searchResult}
                  checkNearStore={this.state.visibleStores}
                  stores={stores}
                  showAllStoresCheck={this.state.showAllStores}
                />
                <span>Show All</span>
              </div>
              <div className="showAllStores">
                <ClearData clearAllData={this.clearAllData} />
                <span>Reset</span>
              </div>
            </div>
            <div className="panelContainer">
              <Paper className="panel" elevation={2}>
                <Snackbar
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "top",
                  }}
                  open={false}
                  // autoHideDuration={6000}
                  message="No stores found near your current location."
                  style={{
                    paddingTop: "80px",
                  }}
                  action={
                    <React.Fragment>
                      <IconButton
                        size="small"
                        aria-label="close"
                        color="inherit"
                        onClick={this.handleClose}
                      >
                        <CloseIcon fontSize="small" />
                      </IconButton>
                    </React.Fragment>
                  }
                />
                {/* <Input
                className="searchInput"
                placeholder={"Please enter province To find a store"}
                onKeyDown={this.onKeyDownSearch}
                // style={{ display: "none" }}
              /> */}
                <>
                  {/* <ReactSearchBox
                  placeholder="Placeholder"
                  value="Doe"
                  data={stores}
                  callback={(record) => console.log(record)}
                /> */}
                </>
                <div className="scrollingCards">
                  {/* <div>
                    Map
                  </div> */}
                  {this.state.searchResult.length > 0 &&
                  this.state.searchResult[0] !== null
                    ? this.state.searchResult.map((obj, key) => {
                        let locationData = this.createStoreData(obj);

                        return (
                          <StoreInfoCard
                            key={key}
                            cusName={obj.cusName}
                            addr1={this.getAddr1(obj)}
                            addr2={this.getAddr2(obj)}
                            searchResultCheck={this.state.searchResult}
                            phoneNum={this.getPhoneNum(obj.tel, obj.mobile)}
                            phoneNum2={this.getPhoneNum(obj.tel2, obj.mobile)}
                            shopLink={this.getShopLink(obj)}
                            onClick={() => this.storeInfoCardOnClick(obj, key)}
                            latlng={locationData.location[0]}
                            currentlocationData={this.state.currentlocation}
                            position={locationData.location[0]}
                            newPostion={this.createStoreData(obj)}
                          />
                        );
                      })
                    : this.state.showAllStores
                    ? stores.map((obj, key) => {
                        let locationData = this.createStoreData(obj);

                        return (
                          <StoreInfoCard
                            key={key}
                            cusName={obj.cusName}
                            addr1={this.getAddr1(obj)}
                            addr2={this.getAddr2(obj)}
                            phoneNum={this.getPhoneNum(obj.tel, obj.mobile)}
                            phoneNum2={this.getPhoneNum(obj.tel2, obj.mobile)}
                            shopLink={this.getShopLink(obj)}
                            onClick={() => this.storeInfoCardOnClick(obj, key)}
                            latlng={locationData.location[0]}
                            currentlocationData={this.state.currentlocation}
                            position={locationData.location[0]}
                            newPostion={this.createStoreData(obj)}
                            searchResultCheck={this.state.searchResult}
                          />
                        );
                      })
                    : this.state.visibleStores.map((obj, key) => {
                        let locationData = this.createStoreData(obj);

                        return (
                          <StoreInfoCard
                            key={key}
                            cusName={obj.cusName}
                            addr1={this.getAddr1(obj)}
                            addr2={this.getAddr2(obj)}
                            phoneNum={this.getPhoneNum(obj.tel, obj.mobile)}
                            phoneNum2={this.getPhoneNum(obj.tel2, obj.mobile)}
                            shopLink={this.getShopLink(obj)}
                            onClick={() => this.storeInfoCardOnClick(obj, key)}
                            latlng={locationData.location[0]}
                            currentlocationData={this.state.currentlocation}
                            position={locationData.location[0]}
                            newPostion={this.createStoreData(obj)}
                            searchResultCheck={this.state.searchResult}
                          />
                        );
                      })}
                </div>
              </Paper>
            </div>
          </>
        )}
        <Map
          google={this.props.google}
          zoom={this.state.zoom}
          style={{ width: "100%", height: "100%" }}
          initialCenter={{ lat: 30.1575, lng: 71.5249 }}
          center={this.state.coord}
          onClick={this.onMapClick}
          mapTypeControl={!isMobile}
        >
          <Marker key="marker_1" position={this.state.currentlocation} />
          {this.state.searchResult.length > 0 &&
          this.state.searchResult[0] !== null
            ? this.state.searchResult.map((obj, key) => {
                // let latLng = obj.geoData.results[0].geometry.location;

                let StoreDataLatLng = this.createStoreData(obj);

                // let storeCoord = obj.geoData.results[0].geometry.location;

                let latLng = StoreDataLatLng.location[0];
                return (
                  <Marker
                    key={key}
                    position={latLng}
                    currentlocationData={this.state.currentlocation}
                    onClick={this.onMarkerClick}
                    phoneNum={this.getPhoneNum(obj.tel, obj.mobile)}
                    phoneNum2={this.getPhoneNum(obj.tel2, obj.mobile)}
                    shopLink={this.getShopLink(obj)}
                    address1={this.getAddr1(obj)}
                    address2={this.getAddr2(obj)}
                    cusName={obj.cusName} // cusName is actually shop name
                    icon={
                       this.highlightPinObj
                        
                    }
                  />
                );
              })
            : this.state.showAllStores
            ? stores.map((obj, key) => {
                let StoreDataLatLng = this.createStoreData(obj);
                let latLng = StoreDataLatLng.location[0];
                return (
                  <Marker
                    key={key}
                    position={latLng}
                    currentlocationData={this.state.currentlocation}
                    onClick={this.onMarkerClick}
                    phoneNum={this.getPhoneNum(obj.tel, obj.mobile)}
                    phoneNum2={this.getPhoneNum(obj.tel2, obj.mobile)}
                    shopLink={this.getShopLink(obj)}
                    address1={this.getAddr1(obj)}
                    address2={this.getAddr2(obj)}
                    cusName={obj.cusName} // cusName is actually shop name
                    icon={
                      obj.pinHighlighted
                        ? this.highlightPinObj
                        : this.normalPinObj
                    }
                  />
                );
              })
            : this.state.visibleStores.map((obj, key) => {
                // let latLng = obj.geoData.results[0].geometry.location;

                let StoreDataLatLng = this.createStoreData(obj);

                // let storeCoord = obj.geoData.results[0].geometry.location;

                let latLng = StoreDataLatLng.location[0];
                return (
                  <Marker
                    key={key}
                    position={latLng}
                    currentlocationData={this.state.currentlocation}
                    onClick={this.onMarkerClick}
                    phoneNum={this.getPhoneNum(obj.tel, obj.mobile)}
                    phoneNum2={this.getPhoneNum(obj.tel2, obj.mobile)}
                    shopLink={this.getShopLink(obj)}
                    address1={this.getAddr1(obj)}
                    address2={this.getAddr2(obj)}
                    cusName={obj.cusName} // cusName is actually shop name
                    icon={
                       this.highlightPinObj
                        
                    }
                  />
                );
              })}

          <InfoWindow
            marker={this.state.activeMarker}
            visible={this.state.showingInfoWindow}
            onOpen={this.infoWindowOnOpen}
          >
            <div id="insideInfoWindow" />
          </InfoWindow>
        </Map>
        {isMobile && (
          <div className="showMobileBar">
            <div className="showTopBarMobile">
              <div className="showSearchMobile">
                <SearchBar
                  stores={stores}
                  visibleStores={this.state.visibleStores}
                  getSearchResult={this.getSearchResult}
                  searchResultCheck={this.state.searchResult}
                />
              </div>
            </div>
            <div className="showMobileIcon">
              <div className="mobileIconBorderDesign">
                <SearchFilterMobile
                  clearAllData={this.clearAllData}
                  getSearchResult={this.getSearchResult}
                  style={{ padding: "4px" }}
                  allStores={stores}
                />
              </div>
              <div className="mobileIconBorderDesign">
                <ClearData
                  clearAllData={this.clearAllData}
                  style={{ padding: "4px" }}
                />
              </div>
              <div className="mobileIconBorderDesign">
                <ShowAllStores
                  getAllStores={this.getAllStores}
                  style={{ padding: "4px" }}
                  showAllStoresCheck={this.state.showAllStores}
                />
              </div>

              <div className="mobileIconBorderDesign">
                <IconButton
                  size="small"
                  aria-label="close"
                  color="inherit"
                  onClick={() => this.setState({ drawerOpen: true })}
                  style={{ padding: "4px" }}
                >
                  <ListIcon />
                </IconButton>
              </div>
            </div>
          </div>
        )}
    
        {isMobile && (
          <>
            <Snackbar
              anchorOrigin={{
                vertical: "top",
                horizontal: "top",
              }}
              open={false}
              // autoHideDuration={6000}
              message="No stores found near your current location."
              style={{
                paddingTop: "80px",
              }}
              action={
                <React.Fragment>
                  <IconButton
                    size="small"
                    aria-label="close"
                    color="inherit"
                    onClick={this.handleClose}
                  >
                    <CloseIcon fontSize="small" />
                  </IconButton>
                </React.Fragment>
              }
            />{" "}
            <Drawer
              anchor="top"
              title="store"
              open={this.state.drawerOpen}
              PaperProps={{ className: "drawerPaper" }}
              variant="persistent"
            >
              <div className="showMobileBar">
                <div className="showTopBarMobile">
                  <div className="showSearchMobile">
                    <SearchBar
                      stores={stores}
                      visibleStores={this.state.visibleStores}
                      getSearchResult={this.getSearchResult}
                      searchResultCheck={this.state.searchResult}
                    />
                  </div>
                </div>
                <div className="showMobileIcon">
                  <div className="mobileIconBorderDesign">
                    <SearchFilterMobile
                      clearAllData={this.clearAllData}
                      getSearchResult={this.getSearchResult}
                      style={{ padding: "4px" }}
                      allStores={stores}
                    />
                  </div>
                  <div className="mobileIconBorderDesign">
                    <ClearData
                      clearAllData={this.clearAllData}
                      style={{ padding: "4px" }}
                    />
                  </div>
                  <div className="mobileIconBorderDesign">
                    <ShowAllStores
                      getAllStores={this.getAllStores}
                      style={{ padding: "4px" }}
                      showAllStoresCheck={this.state.showAllStores}
                    />
                  </div>

                  <div className="mobileIconBorderDesign">
                    <IconButton
                      size="small"
                      aria-label="close"
                      color="inherit"
                      onClick={() =>
                        this.setState((prevState) => ({
                          drawerOpen: !prevState.drawerOpen,
                        }))
                      }
                      style={{ padding: "4px" }}
                    >
                      {this.state.drawerOpen ? (
                        <MapOutlinedIcon />
                      ) : (
                        <ListIcon />
                      )}
                    </IconButton>
                  </div>
                </div>
              </div>

              {this.renderCheckbox()}
              <span style={{ paddingLeft: "10px", fontWeight: "600" }}>
              <span>Shop Link : https://shop.syngenta.com.pk </span> <br/>
                Retailers
              </span>
              <div className="scrollingCards">
                {this.state.searchResult.length > 0 &&
                this.state.searchResult[0] !== null
                  ? this.state.searchResult.map((obj, key) => {
                      let locationData = this.createStoreData(obj);
                      return (
                        <StoreInfoCard
                          key={key}
                          cusName={obj.cusName}
                          addr1={this.getAddr1(obj)}
                          addr2={this.getAddr2(obj)}
                          phoneNum={this.getPhoneNum(obj.tel, obj.mobile)}
                          phoneNum2={this.getPhoneNum(obj.tel2, obj.mobile)}
                          shopLink={this.getShopLink(obj)}
                          onClick={() => this.storeInfoCardOnClick(obj, key)}
                          latlng={locationData.location[0]}
                          currentlocationData={this.state.currentlocation}
                          newPostion={this.createStoreData(obj)}
                          searchResultCheck={this.state.searchResult}
                        />
                      );
                    })
                  : this.state.showAllStores
                  ? stores.map((obj, key) => {
                      let locationData = this.createStoreData(obj);
                      return (
                        <StoreInfoCard
                          key={key}
                          cusName={obj.cusName}
                          addr1={this.getAddr1(obj)}
                          addr2={this.getAddr2(obj)}
                          phoneNum={this.getPhoneNum(obj.tel, obj.mobile)}
                          phoneNum2={this.getPhoneNum(obj.tel2, obj.mobile)}
                          shopLink={this.getShopLink(obj)}
                          onClick={() => this.storeInfoCardOnClick(obj, key)}
                          latlng={locationData.location[0]}
                          currentlocationData={this.state.currentlocation}
                          newPostion={this.createStoreData(obj)}
                          searchResultCheck={this.state.searchResult}
                        />
                      );
                    })
                  : this.state.visibleStores.map((obj, key) => {
                      let locationData = this.createStoreData(obj);
                      return (
                        <StoreInfoCard
                          key={key}
                          cusName={obj.cusName}
                          addr1={this.getAddr1(obj)}
                          addr2={this.getAddr2(obj)}
                          phoneNum={this.getPhoneNum(obj.tel, obj.mobile)}
                          phoneNum2={this.getPhoneNum(obj.tel2, obj.mobile)}
                          shopLink={this.getShopLink(obj)}
                          onClick={() => this.storeInfoCardOnClick(obj, key)}
                          latlng={locationData.location[0]}
                          currentlocationData={this.state.currentlocation}
                          newPostion={this.createStoreData(obj)}
                          searchResultCheck={this.state.searchResult}
                        />
                      );
                    })}
              </div>

              {/* <ButtonBase
                className="closeDrawer"
                onClick={() => this.setState({ drawerOpen: false })}
              >
                <ExpandLess />
              </ButtonBase> */}
            </Drawer>
          </>
        )}
        </Typography>
      </div>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: apiKey,
})(MapContainer);


