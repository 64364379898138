import React from 'react';
import './App.css';
import MapContainer from './MapContainer.js';
import ReactGA from 'react-ga';
 import CssBaseline from "@mui/material/CssBaseline";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
// import Typography from "@mui/material/Typography";
import Typography from '@material-ui/core/Typography';
// import Noto from './fonts/noto-sans-v27-latin-200.woff2'
const theme = createMuiTheme({
  typography: {
    fontFamily: "Noto Sans, sans-serif"
  },
  
});
export default class App extends React.Component {
  componentDidMount() {
    ReactGA.initialize("UA-140890987-1");
    ReactGA.pageview(window.location.pathname);
  }

  render() {
    return (
      <MuiThemeProvider theme={theme}>
        <Typography>
          <CssBaseline />
      <MapContainer/>
      </Typography>
      </MuiThemeProvider>
    );
  }
}
