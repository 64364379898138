import * as React from "react";
import Checkbox from "@mui/material/Checkbox";
// import FormControlLabel from "@mui/material/FormControlLabel";
import { isMobile } from "react-device-detect";
// import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
export default function ShowAllStores(props) {
  const [checked, setChecked] = React.useState(false);
  React.useEffect(() => {
    setChecked(props.showAllStoresCheck);
  }, [props.showAllStoresCheck, props.checkNearStore]);

  const handleChange = (event) => {
    setChecked(event.target.checked);
    props.getAllStores(event.target.checked);
  };

  return (
    <>
      <Tooltip title="Show all stores">
        <Checkbox
          checked={checked}
          onChange={handleChange}
          color="default"
          style={{
            padding: isMobile ? "4px" : "4px",
            flexDirection: "column",
            fontSize: "12px",
            fontFamily: "Roboto",
          }}
        />
      </Tooltip>
      {/* <span>Show All</span> */}
    </>
    // <FormGroup style={{ backgroundColor: "#ffffff" }}>
    //   <FormControlLabel
    //     style={{ backgroundColor: "#ffffff" }}
    //     control={
    //       <Tooltip title="View all stores">
    //         <Checkbox
    //           checked={checked}
    //           onChange={handleChange}
    //           inputProps={{ "aria-label": "controlled" }}
    //         />
    //       </Tooltip>
    //     }
    //     // label="Show All"
    //   />
    // </FormGroup>
  );
}
