import * as React from "react";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Checkbox from "@mui/material/Checkbox";
import { useState } from "react";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import _ from "lodash";
//import TreeView from "@mui/lab/TreeView";
import { TreeView } from '@mui/x-tree-view/TreeView';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
//import TreeItem from "@mui/lab/TreeItem";
import { TreeItem } from '@mui/x-tree-view/TreeItem';

export default function SearchFilter(props) {
  const [open, setOpen] = useState(false);
  const [districtOpen, setDistrictOpen] = useState(false);
  const [showDistrict, setShowDistrict] = useState([]);
  const [tehsilOpen, setTehsilOpen] = useState(false);
  const [showTehsil, setShowTehsil] = useState([]);
  const [regionOpen, setRegionOpen] = useState(false);
  const [showRegion, setShowRegion] = useState([]);
  //   React.useEffect(() => {
  //     console.log("serach check", props.searchResultCheck);
  //     if (!props.searchResultCheck && districtOpen) {
  //       setDistrictOpen(false);
  //     } else if (!props.searchResultCheck && tehsilOpen) {
  //       setTehsilOpen(false);
  //     } else if (!props.searchResultCheck && regionOpen) {
  //       setRegionOpen(false);
  //     }
  //   });
  const handleDistrictOpen = (event) => {
    const { value, checked } = event.target;
    setDistrictOpen(checked);
    if (value === "District") {
      var emps = _.groupBy(props.allStores, function (item) {
        return item.District;
      });
      setShowDistrict(emps);
    }
  };
  const handleTehsilOpen = (event) => {
    const { value, checked } = event.target;
    setTehsilOpen(checked);
    if (value === "Tehsil") {
      var emps = _.groupBy(props.allStores, function (item) {
        return item.Tehsil;
      });
      console.log("tehsil", emps)
      setShowTehsil(emps);
    }
  };
  const handleRegionOpen = (event) => {
    const { value, checked } = event.target;
    setRegionOpen(checked);
    if (value === "Region") {
      var emps = _.groupBy(props.allStores, function (item) {
        return item.Region;
      });
      setShowRegion(emps);
    }
  };
  const showDistrictChildren = Object.entries(showDistrict)
    .sort()
    .map(([key, value]) => {
      return (
        <TreeItem
          nodeId={key}
          label={key}
          onClick={() => props.getSearchResult(value)}
        >
          {value.map((item) => (
            <TreeItem
              nodeId={item.addr}
              icon={<LocationOnIcon />}
              //   label={`${item.addr}, ${item.Tehsil}, ${item.District}`}
              label={item.addr}
              onClick={() => props.getSearchResult([item])}
            />
          ))}
        </TreeItem>
      );
    });
  const showTehsilChildren = Object.entries(showTehsil)
    .sort()
    .map(([key, value]) => {
      return (
        <TreeItem
          nodeId={key}
          label={key}
          onClick={() => props.getSearchResult(value)}
        >
          {value.map((item) => (
            <TreeItem
              nodeId={item.addr}
              icon={<LocationOnIcon />}
              // label={`${item.addr}, ${item.Tehsil}, ${item.District}`}
              label={item.addr}
              onClick={() => props.getSearchResult([item])}
            />
          ))}
        </TreeItem>
      );
    });
  const showRegionChildren = Object.entries(showRegion)
    .sort()
    .map(([key, value]) => {
      return (
        <TreeItem
          nodeId={key}
          label={key}
          onClick={() => props.getSearchResult(value)}
        >
          {value.map((item) => (
            <TreeItem
              nodeId={item.addr}
              icon={<LocationOnIcon />}
              // label={`${item.addr}, ${item.Tehsil}, ${item.District}`}
              label={item.addr}
              onClick={() => props.getSearchResult([item])}
            />
          ))}
        </TreeItem>
      );
    });

  return (
    <>
      {/* <React.Fragment key={"left"}> */}
      <>
        <Tooltip title="Filter">
          <IconButton
            color="inherit"
            aria-label="open drawer"
            label="Filter"
            //   edge="start"
            style={{
              flexDirection: "column",
              fontSize: "12px",
              fontFamily: "Roboto",
              padding: "4px",
            }}
            onClick={() => setOpen(!open)}
          >
            {open ? <FilterAltIcon /> : <FilterAltOutlinedIcon />}
          </IconButton>
        </Tooltip>
        {/* <span>Filter</span> */}
      </>

      {/* <Button onClick={() => setOpen(true)}>Filter</Button> */}

      <Drawer
        PaperProps={{ style: { minWidth: "200px" } }}
        anchor={"left"}
        open={open}
        onClose={() => setOpen(false)}
      >
        <List>
          
          <ListItem>
            <ListItemIcon>
              <Checkbox
                value="Region"
                checked={regionOpen}
                onChange={handleRegionOpen}
              />
            </ListItemIcon>
            <ListItemText>Province</ListItemText>
          </ListItem>
          {regionOpen ? (
            <TreeView
              aria-label="file system navigator"
              className="treeViewRoot"
              style={{ marginLeft: "20px" }}
              defaultCollapseIcon={<ExpandMoreIcon />}
              defaultExpandIcon={<ChevronRightIcon />}
              //   sx={{
              //     height: 240,
              //     flexGrow: 1,
              //     maxWidth: 400,
              //     overflowY: "auto",
              //   }}
            >
              {showRegionChildren}
            </TreeView>
          ) : (
            ""
          )}
          <ListItem>
            <ListItemIcon>
              <Checkbox
                value="District"
                checked={districtOpen}
                onChange={handleDistrictOpen}
              />
            </ListItemIcon>
            <ListItemText>District</ListItemText>
          </ListItem>
          {districtOpen ? (
            <TreeView
              aria-label="file system navigator"
              style={{ marginLeft: "20px" }}
              defaultCollapseIcon={<ExpandMoreIcon />}
              defaultExpandIcon={<ChevronRightIcon />}
            >
              {showDistrictChildren}
            </TreeView>
          ) : (
            ""
          )}
          <ListItem>
            <ListItemIcon>
              <Checkbox
                value="Tehsil"
                checked={tehsilOpen}
                onChange={handleTehsilOpen}
              />
            </ListItemIcon>
            <ListItemText>Tehsil</ListItemText>
          </ListItem>
          {tehsilOpen ? (
            <TreeView
              aria-label="file system navigator"
              style={{ marginLeft: "20px" }}
              defaultCollapseIcon={<ExpandMoreIcon />}
              defaultExpandIcon={<ChevronRightIcon />}
              //   sx={{
              //     height: 240,
              //     flexGrow: 1,
              //     maxWidth: 400,
              //     overflowY: "auto",
              //   }}
            >
              {showTehsilChildren}
            </TreeView>
          ) : (
            ""
          )}
        </List>
      </Drawer>
      {/* </React.Fragment> */}
    </>
  );
}
