import * as React from "react";
import Button from "@mui/material/Button";
import { useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

export default function ShowDailog(props) {
  const { openVal } = props;
  const [open, setOpen] = React.useState(openVal);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("xs" || "sm"));
  useEffect(() => {
    setOpen(openVal);
  }, [openVal]);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {"Use location service?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Location is not enabled. Please enable to use this feature.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {/* <Button autoFocus onClick={handleClose}>
            Disagree
          </Button> */}
          <Button onClick={handleClose} autoFocus>
            ok
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
