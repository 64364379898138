import * as React from "react";

import IconButton from "@mui/material/IconButton";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import Tooltip from "@mui/material/Tooltip";
export default function ClearData(props) {
  return (
    <>
      <Tooltip title="Reset">
        <IconButton
          color="inherit"
          aria-label="open drawer"
          //   edge="start"
          onClick={() => props.clearAllData(true)}
          style={{
            padding: "4px",
            flexDirection: "column",
            fontSize: "12px",
            fontFamily: "Roboto",
          }}
        >
          <RestartAltIcon />
        </IconButton>
      </Tooltip>
      {/* <span>Reset</span> */}
    </>
  );
}
