import React, { Component } from "react";
// import DirectionsSharpIcon from "@material-ui/icons/DirectionsSharp";
import AssistantDirectionOutlinedIcon from "@mui/icons-material/AssistantDirectionOutlined";
import IconButton from "@material-ui/core/IconButton";
import LocalMallOutlinedIcon from "@mui/icons-material/LocalMallOutlined";
// import { getDistance } from "geolib";

// import PhoneSharpIcon from "@material-ui/icons/PhoneSharp";
export default class StoreInfo extends Component {
  render() {""
  
    // const dist = this.props.open
    //   ? getDistance(
    //       {
    //         latitude: this.props.currentlocationData.lat,
    //         longitude: this.props.currentlocationData.lng,
    //       },
    //       { latitude: this.props.latlng.lat, longitude: this.props.latlng.lng }
    //     )
    //   : 0;

    return (
      <div className={this.props.cssClass}>
        <h4 style={{fontFamily:'Noto Sans' }}>{this.props.cusName}</h4>
        <div style={{ width: "100%", fontSize: "12px", display: "flex", fontFamily:'Noto Sans' }}>
          <div style={{ fontSize: "12px", width:'80%', fontWeight:'500' }}>{this.props.addr1}</div>
          {/* <br /> */}

          {/* {`Distance ${dist ? dist / 1000 : 0}
        km`} */}

          {/* {this.props.addr2} */}

          <div
            style={{
              marginTop: "5px",
              display: "flex",

              fontSize: "92%",
            }}
          >
            {/* <a
            href={
              "https://www.google.com/maps/dir/?api=1&destination_place_id=" +
              "&destination=" +
              this.props.latlng
            }
            target="_blank"
            rel="noopener noreferrer"
          >
            <DirectionsSharpIcon color="primary" />
          </a> */}
            {/* <a
            href={
              "https://www.google.com/maps/dir/" +
              this.props.currentlocationData.lat +
              "," +
              this.props.currentlocationData.lng +
              "/" +
              this.props.latlng.lat +
              "," +
              this.props.latlng.lng
            }
            target="_blank"
            rel="noopener noreferrer"
          >
            <AssistantDirectionOutlinedIcon color="primary" />
          </a> */}
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                textAlign: "center",
                marginRight: "11px",
                marginLeft: "5px",
              }}
            >
              <IconButton
                style={{ padding: "0px" }}
                onClick={() =>
                  window.open(
                    "https://www.google.com/maps/dir/" +
                      this.props.currentlocationData.lat +
                      "," +
                      this.props.currentlocationData.lng +
                      "/" +
                      this.props.latlng.lat +
                      "," +
                      this.props.latlng.lng,
                    "_blank"
                  )
                }
              >
                <AssistantDirectionOutlinedIcon color="primary" />
              </IconButton>
              <span>Directions</span>
            </div>
            {this.props.shopLink ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  textAlign: "center",
                }}
              >
                <IconButton color="primary" style={{ padding: "0px" }} onClick={() =>
                  window.open(
                    `${this.props.shopLink}`,
                    "_blank"
                  )
                }>
                  <LocalMallOutlinedIcon color="primary" />
                </IconButton>
                <span>Shop</span>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
        {/* <h4>{this.props.phoneNum !== "" && "Phone number"}</h4> */}
        {/* <PhoneSharpIcon color="primary" /> {""} */}
        {/* <span style={{ position: "relative", top: "-3px" }}> */}
        <a
          href={`tel:+ ${this.props.phoneNum}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {`+${this.props.phoneNum}`} 
        </a>
        <br />
        {
          this.props.phoneNum2 ? 
          <a
          href={`tel:+ ${this.props.phoneNum2}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {`+${this.props.phoneNum2}`} 
        </a>
        : ''
        }
        {/* </span> */}
      </div>
    );
  }
}
