import * as React from "react";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";

// import { useTheme } from "@mui/material/styles";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useState } from "react";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import _ from "lodash";

import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined";
import Button from "@mui/material/Button";

export default function SearchFilterMobile(props) {
  const [open, setOpen] = useState(false);
  const [showDistrict, setShowDistrict] = useState([]);
  const [showTehsil, setShowTehsil] = useState([]);
  const [showRegion, setShowRegion] = useState([]);
//   const theme = useTheme();
  const [selectedRegion, setSelectedRegion] = React.useState([]);
  const [selectedDistrict, setSelectedDistrict] = React.useState([]);
  const [selectedTehshil, setSelectedTehshil] = React.useState([]);
  const [filterResult, setFilterResult] = React.useState([]);

  React.useEffect(() => {
    const getRegion = _.groupBy(props.allStores, function (item) {
      return item.Region;
    });
    setShowRegion(getRegion);
  }, [props.allStores]);

  const handleProvinceChange = (event) => {
    const { value } = event.target;
    const getDistrict = Object.entries(props.allStores)
      .map(([key, val]) => val)
      .filter((val) => val.Region === value.toString());
    const selectedDistrictValue = _.groupBy(getDistrict, function (item) {
      return item.District;
    });
    setShowDistrict(selectedDistrictValue);
    setSelectedRegion(typeof value === "string" ? value.split(",") : value);
    setShowTehsil([]);
    setFilterResult(getDistrict);
  };

  const handleDistrictChange = (event) => {
    const { value } = event.target;
    const getTahshil = Object.entries(props.allStores)
      .map(([key, val]) => val)
      .filter((val) => val.District === value.toString());
    const selectedTehshilValue = _.groupBy(getTahshil, function (item) {
      return item.Tehsil;
    });
    setShowTehsil(selectedTehshilValue);
    setFilterResult(getTahshil);
    setSelectedDistrict(typeof value === "string" ? value.split(",") : value);
  };
  const handleButtonClick = () => {
    setOpen(false);
    props.getSearchResult(filterResult);
  };

  const clearFilter = () => {
    setSelectedRegion([]);
    setFilterResult([]);
    setShowDistrict([]);
    setShowTehsil([]);
    setSelectedDistrict([]);
    setSelectedTehshil([]);
    props.getSearchResult(false);
  };

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    const getTahshil = Object.entries(props.allStores)
      .map(([key, val]) => val)
      .filter((val) => val.Tehsil === value.toString());
    setSelectedTehshil(typeof value === "string" ? value.split(",") : value);

    setFilterResult(getTahshil);
  };

  const showProvinceChildren = Object.entries(showRegion)
    .sort()
    .map(([key, value]) => {
      return (
        <MenuItem key={key} value={key}>
          {key}
        </MenuItem>
      );
    });

  const showDistrictChildren = Object.entries(showDistrict)
    .sort()
    .map(([key, value]) => {
      return (
        <MenuItem key={key} value={key}>
          {key}
        </MenuItem>
      );
    });

  const showTehsilChildren = Object.entries(showTehsil)
    .sort()
    .map(([key, value]) => {
      return (
        <MenuItem key={key} value={key}>
          {key}
        </MenuItem>
      );
    });

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };


  return (
    <>
      <>
        <Tooltip title="Filter">
          <IconButton
            color="inherit"
            aria-label="open drawer"
            label="Filter"
            //   edge="start"
            size="small"
            style={{
              flexDirection: "column",
              fontSize: "12px",
              fontFamily: "Roboto",
              padding: "4px",
            }}
            onClick={() => setOpen(!open)}
          >
            {open ? <FilterAltIcon /> : <FilterAltOutlinedIcon />}
          </IconButton>
        </Tooltip>
      </>

      <Drawer
        PaperProps={{ style: { minWidth: "100%" } }}
        anchor={"left"}
        open={open}
        onClose={() => setOpen(false)}
      >
        <div style={{ position: "relative", height: "100%", width: "100%" }}>
          <div className="drawerHeader">
            <div>
              <IconButton
                style={{ padding: "0px", color: "white" }}
                onClick={() => setOpen(!open)}
              >
                <ArrowBackIosNewOutlinedIcon />
              </IconButton>
            </div>

            <div style={{ position: "relative", left: "35%" }}>FILTER</div>
          </div>
          <div style={{ position: "relative", top: "20px", padding: "20px" }}>
            <FormControl sx={{ m: 1, width: "95%", paddingBottom: "10px" }}>
              <InputLabel id="demo-multiple-name-label">Province</InputLabel>
              <Select
                labelId="demo-multiple-name-label"
                id="demo-multiple-name"
                value={selectedRegion}
                onChange={handleProvinceChange}
                input={<OutlinedInput label="Name" />}
                MenuProps={MenuProps}
              >
                {showProvinceChildren}
              </Select>
            </FormControl>

            <FormControl sx={{ m: 1, width: "95%", paddingBottom: "10px" }}>
              <InputLabel id="demo-multiple-name-label">District</InputLabel>
              <Select
                labelId="demo-multiple-name-label"
                id="demo-mul-name"
                disabled={Object.keys(showDistrict).length < 1}
                value={selectedDistrict}
                onChange={handleDistrictChange}
                input={<OutlinedInput label="Name" />}
                MenuProps={MenuProps}
              >
                {showDistrictChildren}
              </Select>
            </FormControl>
            <FormControl sx={{ m: 1, width: "95%", paddingBottom: "10px" }}>
              <InputLabel id="demo-multiple-name-label">Tehsil</InputLabel>
              <Select
                labelId="demo-multiple-name-label"
                id="demo-name"
                disabled={Object.keys(showTehsil).length < 1}
                value={selectedTehshil}
                onChange={handleChange}
                input={<OutlinedInput label="Name" />}
                MenuProps={MenuProps}
              >
                {showTehsilChildren}
              </Select>
            </FormControl>
          </div>

          <footer className="filterFooter">
            <div className="footerButton">
              <Button
                variant="outlined"
                onClick={clearFilter}
                color="success"
                style={{ width: "40%" }}
              >
                Clear
              </Button>

              <Button
                variant="contained"
                onClick={handleButtonClick}
                color="success"
                style={{ width: "40%" }}
                disabled={Object.keys(showDistrict).length < 1}
              >
                Apply
              </Button>
            </div>
          </footer>
        </div>
      </Drawer>
      {/* </React.Fragment> */}
    </>
  );
}
